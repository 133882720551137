import { useState, useEffect, useCallback, createContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import throttle from "lodash.throttle";
import debounce from "lodash.debounce";
import uuid from "react-uuid";

import WidgetToggleButton from "./components/WidgetToggleButton";
import ChatWidget from "./components/ChatWidget";

import { SMARTFLOWS_SOCKET_URL, SOCKET_URL } from "./constants/endpoints";

import {
  getOrgActions,
  setOrgSubdomain,
  setParentOrigin,
  setParentPathname,
  setCustomizationProfile,
  updateLanguage
} from "./redux/actions/orgActions";
import {
  setWidgetOrientation,
  setWindowDimensions
} from "./redux/actions/contentActions";
import {
  getChatActions,
  updateActivity,
  logEventRecord,
  setEndUserDetails,
  setAdditionalDetails
} from "./redux/actions/chatActions";
import { getContentActions } from "./redux/actions/contentActions";
import { cancelUtterance } from "./redux/actions/speechSynthesisActions";
import { abortAudioRecording } from "./redux/actions/mediaRecorderActions";
import { createChatRecord } from "./api";
import { censorPII } from "./lib/censorPII";
import { mergeChatRecords } from "./lib/chat/stringify";
import messageParent from "./lib/messageParent";
import { parseUserDetails } from "./lib/chat/parseUserDetails";
import { getConversationType } from "./lib/chat/conversationType";
import useAmazonConnect from "./hooks/amazonConnect";
import useWebSocketWrapper from "./hooks/websocketWrapper";

import { DEFAULT_THEME } from "./constants/defaults";
import {
  INTERACTED_WITH_AI,
  CHAT_WITH_AGENT,
  AMZ_CONNECT_CUSTOMER_ROLE
} from "./constants/chat";
import {
  WIDGET_AUTO_LAUNCH_EVENT,
  WIDGET_READY_EVENT
} from "./constants/events";
import { OPEN_MESSAGE, READY_MESSAGE } from "./constants/widget";
import { STANDALONE_WEB, WEB } from "./constants/platform";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const WebSocketContext = createContext();

function App() {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const profile = useSelector((state) => state?.org?.profile);
  const platform = useSelector((state) => state?.org?.platform);
  const subdomain = useSelector((state) => state?.org?.subdomain);
  const parentOrigin = useSelector((state) => state?.org?.parentOrigin);
  const parentPathname = useSelector((state) => state?.org?.parentPathname);
  const orgDetails = useSelector((state) => state?.org?.details);
  const customizations = useSelector((state) => state?.org?.customizations);
  const assetUrls = useSelector((state) => state?.org?.assetUrls);
  const criticalFailure = useSelector((state) => state?.org?.criticalFailure);

  const chatRecordId = useSelector((state) => state?.chat?.chatRecordId);
  const chatOn = useSelector((state) => state?.chat?.chatOn);
  const chatWith = useSelector((state) => state?.chat?.chatWith);
  const chatMedium = useSelector((state) => state?.chat?.chatMedium);
  const liveChatSocketUrl = useSelector((state) => state?.chat?.liveChatSocketUrl);

  const dispatch = useDispatch();
  const orgActionsFn = getOrgActions(dispatch);
  const chatActionsFn = getChatActions(dispatch);
  const contentActionsFn = getContentActions(dispatch);

  const isWidgetReady =
    !!orgDetails?.id && !!customizations?.chatConfig && !criticalFailure;
  const chattingWithAgent = chatWith === CHAT_WITH_AGENT;
  const isOnWeb = platform === WEB;
  const botChatSocketUrl = !!orgDetails?.enableSmartflows
    ? SMARTFLOWS_SOCKET_URL
    : SOCKET_URL;
  const socketUrl = chattingWithAgent
    ? liveChatSocketUrl
    : botChatSocketUrl;

  const { sendJsonMessage } = useWebSocketWrapper(
    orgDetails?.id,
    profile,
    parentOrigin,
    parentPathname,
    chatRecordId,
    socketUrl,
    chatOn && isWidgetReady,
    chatWith,
    chatMedium,
    chatActionsFn,
    contentActionsFn
  );
  const {
    amzConnect,
    amzSendMessage
  } = useAmazonConnect(orgDetails, customizations, chatOn, chatWith);

  const dispatchSetWindowDimensions = (dimensions) => {
    if (Number.isNaN(parseInt(dimensions?.width))
      || Number.isNaN(parseInt(dimensions?.height))) {
      return;
    }
    dispatch(setWindowDimensions({
      width: dimensions.width,
      height: dimensions.height
    }));
  };

  const parseUrlFragment = () => {
    const url = window.location.href;
    const [href, fragment] = url.split("#");
    const pairs = (fragment || "").split("&");
    let hasPlatform = false;

    const parseValue = (v) => {
      try {
        return JSON.parse(decodeURIComponent(v || ""));
      } catch(error) {
        console.warn(`Readyly ChatAssist: failed to parse: ${v}`);
      }
    };

    for (const pair of pairs) {
      const [key, value] = pair.split("=");
      const parsedValue = parseValue(value);

      switch(key) {
        case "platform":
          orgActionsFn.setPlatform(`${parsedValue}`);
          hasPlatform = true;
          break;
        case "orgName":
          dispatch(setOrgSubdomain(`${parsedValue}`.toLowerCase()));
          break;
        case "profile":
          dispatch(setCustomizationProfile(`${parsedValue}`.toLowerCase()));
          break;
        case "parentOrigin":
          dispatch(setParentOrigin(`${parsedValue}`));
          break;
        case "parentPathname":
          dispatch(setParentPathname(`${parsedValue}`));
          break;
        case "orientation":
          dispatch(setWidgetOrientation(`${parsedValue}`));
          break;
        case "windowSize":
          dispatchSetWindowDimensions(parsedValue);
          break;
        case "currentUser":
          dispatch(setEndUserDetails(
            parseUserDetails(parsedValue, false),
            sendJsonMessage
          ));
          break;
        case "lang":
          dispatch(updateLanguage(parsedValue, true));
          break;
        default:
          break;
      }
    }

    if (!hasPlatform) {
      orgActionsFn.setPlatform(WEB);
    }
  };

  const handleMessages = () => {
    const listener = (event) => {
      if (event.origin !== parentOrigin
        && event.origin !== window.location.origin
      ) {
        return;
      }

      try {
        const data = JSON.parse(event.data || "{type:null}");

        const type = data.type;
        const payload = data.payload;

        switch(type) {
          case "_ft_window_resize_":
            dispatchSetWindowDimensions(payload);
            break;
          case "_ft_auto_launch_":
            messageParent(JSON.stringify({
              type: OPEN_MESSAGE
            }), platform, parentOrigin);
            dispatch(logEventRecord(
              "",
              null,
              uuid(),
              WIDGET_AUTO_LAUNCH_EVENT,
              WIDGET_AUTO_LAUNCH_EVENT,
              true,
              ""
            ));
            setIsWidgetOpen(true);
            break;
          case "_ft_user_details_":
            dispatch(setEndUserDetails(
              parseUserDetails(payload, false),
              sendJsonMessage
            ));
            break;
          case "_ft_additional_details_":
            dispatch(setAdditionalDetails(
              `${payload?.details ?? ""}`,
              payload?.options
            ));
            break;
          case "_ft_set_lang_":
            dispatch(updateLanguage(`${payload?.lang || ""}`, true));
            break;
          default:
            break;
        }
      } catch(error) {
        console.warn(`Readyly ChatAssist: Failed to parse message: ${event.data}`);
        return;
      }
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  };

  const loadOrgDetails = () => {
    if (!subdomain) {
      return;
    }
    orgActionsFn.loadOrganizationDetails(subdomain);
  };

  const loadFontStylesheet = () => {
    if (!customizations?.font || !assetUrls?.fonts) {
      return;
    }

    const linkAttribute = "data-font-link";
    const links = document.getElementsByTagName("link");
    for (const l of links) {
      if (l?.getAttribute(linkAttribute)) {
        return;
      }
    }

    const link = document.createElement("link");
    link.setAttribute(linkAttribute, "true");
    link.rel = "stylesheet";
    link.href = `${assetUrls.fonts}/${customizations.font}`;

    document.head.appendChild(link);
  };

  const trackUserActivity = () => {
    if (!isWidgetReady || !isWidgetOpen) {
      return;
    }

    const logActivity = (type) => {
      dispatch(updateActivity(type));
    };

    const handler = throttle((event) => {
      logActivity(event?.type);
    }, 2500);

    document.addEventListener("mousedown", handler);
    document.addEventListener("mousemove", handler);
    document.addEventListener("touchstart", handler);
    document.addEventListener("touchmove", handler);
    document.addEventListener("keydown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("mousemove", handler);
      document.removeEventListener("touchstart", handler);
      document.removeEventListener("touchmove", handler);
      document.removeEventListener("keydown", handler);
    };
  };

  const disconnectAmzConnectParticipant = async (recordObj) => {
    if (!recordObj?.amzContactId
        || !recordObj?.amzParticipantId
        || !recordObj?.amzParticipantToken
        || !recordObj?.amzRegion) {
      return;
    }

    try {
      const prevSession = await amzConnect.ChatSession.create({
        options: {
          region: recordObj.amzRegion
        },
        chatDetails: {
          ContactId: recordObj.amzContactId,
          ParticipantId: recordObj.amzParticipantId,
          ParticipantToken: recordObj.amzParticipantToken
        },
        type: AMZ_CONNECT_CUSTOMER_ROLE
      });
      await prevSession.connect();
      await prevSession.disconnectParticipant();
    } catch (error) {
      console.error(error);
      console.warn("Readyly ChatAssist: failed to disconnect Connect chat!");
    }
  };

  const createRecordFromPreviousChat = async (recordObj, orgDetails) => {
    if (recordObj?.chatStage < INTERACTED_WITH_AI
        || !recordObj?.aiChatRecord
      ) {
      return;
    }

    try {
      const chat = mergeChatRecords(
        recordObj?.aiChatRecord,
        recordObj?.agentChatRecord,
        recordObj?.leftDetailsRecord
      );

      if (chat?.length <= recordObj?.lastSavedChatRecord?.recordLength) {
        window.sessionStorage.removeItem(`${subdomain}_${recordObj?.chatRecordId}`);
        return;
      }

      const chatEventsRecord = recordObj?.chatEventsRecord;
      const eventsRecord = Array.isArray(chatEventsRecord)
        ? chatEventsRecord?.map((id) => ({ id }))
        : [];

      await createChatRecord(
        orgDetails?.id,
        recordObj?.chatRecordId,
        censorPII(chat),
        recordObj?.email || "anonymous",
        {},
        eventsRecord,
        getConversationType(recordObj?.chatStage),
        "",
        recordObj?.endUser,
        recordObj?.profile,
        recordObj?.platform,
        recordObj?.origin,
        {
          orgName: subdomain,
          conversationId: recordObj?.chatRecordId,
          profile: recordObj?.profile,
          platform: recordObj?.platform,
          parentOrigin: recordObj?.origin
        },
        true
      );
    } catch(error) {
      console.warn("Readyly ChatAssist: Failed to create chat record!");
    } finally {
      window.sessionStorage.removeItem(`${subdomain}_${recordObj?.chatRecordId}`);
    }
  };

  const checkLocalStorage = async () => {
    if (!isWidgetReady) {
      return;
    }

    const record = localStorage.getItem(orgDetails?.id);

    if (!record) {
      return;
    }

    let recordObj;
    try {
      recordObj = JSON.parse(record);
    } catch (error) {
      console.warn("Readyly ChatAssist: Malformed previous session data found!");
      localStorage.removeItem(orgDetails?.id);
      return;
    }

    disconnectAmzConnectParticipant(recordObj);
    createRecordFromPreviousChat(recordObj, orgDetails);

    localStorage.removeItem(orgDetails?.id);
  };

  const logReadyEvent = () => {
    if (!isWidgetReady) {
      return;
    }

    messageParent(JSON.stringify({
      type: READY_MESSAGE
    }), platform, parentOrigin);

    dispatch(logEventRecord(
      "",
      null,
      uuid(),
      WIDGET_READY_EVENT,
      WIDGET_READY_EVENT,
      true,
      ""
    ));
  };

  const handleWindowResize = () => {
    if (platform !== STANDALONE_WEB) {
      return;
    }
    const callback = debounce(() => {
      dispatchSetWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, 300);
    dispatchSetWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  };

  useEffect(parseUrlFragment, []);
  useEffect(loadOrgDetails, [subdomain]);
  useEffect(handleMessages, [parentOrigin]);
  useEffect(loadFontStylesheet, [customizations?.font, assetUrls?.fonts]);
  useEffect(trackUserActivity, [isWidgetReady, isWidgetOpen]);
  useEffect(() => checkLocalStorage(), [isWidgetReady]);
  useEffect(logReadyEvent, [isWidgetReady]);
  useEffect(handleWindowResize, [platform]);

  const prepareTheme = () => {
    return {
      ...DEFAULT_THEME,
      ...customizations?.theme
    };
  };

  const toggleWidgetOpen = useCallback((isOpen) => {
    if (!isOpen) {
      dispatch(cancelUtterance());
      dispatch(abortAudioRecording());
    }
    setIsWidgetOpen(isOpen);
  }, []);

  return (
    <WebSocketContext.Provider
      value={{ sendJsonMessage, amzSendMessage }}
    >  
      <ThemeProvider theme={prepareTheme()}>
        {isWidgetReady && (
          <Container>
            <ChatWidget
              isWidgetOpen={!isOnWeb || isWidgetOpen}
              toggleWidgetOpen={toggleWidgetOpen} />
            <WidgetToggleButton 
              isWidgetOpen={!isOnWeb || isWidgetOpen}
              toggleWidgetOpen={toggleWidgetOpen} />
          </Container>
        )}
      </ThemeProvider>
    </WebSocketContext.Provider>
  );
}

export default App;
