import { useState, useEffect, useRef, useContext } from "react";
import { useSpring, animated } from "@react-spring/web";
import styled, { useTheme } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import ShortTextIcon from "@mui/icons-material/ShortText";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  chatStarted,
  clearChat,
  getChatActions
} from "../../redux/actions/chatActions";
import useFeedback from "../../hooks/feedbackHook";
import useLocalization from "../../hooks/localization";
import messageParent from "../../lib/messageParent";
import {
  INTERACTED_WITH_AGENT,
  INTERACTED_WITH_AI,
  RESET_TIMEOUT,
  TICKET_TIMEOUT,
  TRIED_CREATING_TICKET
} from "../../constants/chat";
import {
  WIDGET_OPEN_EVENT,
  WIDGET_CLOSE_EVENT,
  WIDGET_SUB_EVENT
} from "../../constants/events";
import {
  CLOSE_MESSAGE,
  EXPAND_MESSAGE,
  OPEN_MESSAGE,
  LAUNCH_BTN_SIZE_MESSAGE,
  WIDGET_SIZE_EXPANDED,
} from "../../constants/widget";
import { WebSocketContext } from "../../App";
import { DEFAULT_WIDGET_OFFSET } from "../../constants/defaults";
import { STANDALONE_WEB, WEB } from "../../constants/platform";

const TRANSITION_TIME = 150; // ms

const ToggleButtonContainer = styled.div`
  position: absolute;
  padding: 0;
  bottom: 0;
  ${({ orientation }) => orientation}: 0;
  padding-${({ orientation }) => `${orientation}: ${DEFAULT_WIDGET_OFFSET[orientation]}`}px;
  padding-bottom: ${DEFAULT_WIDGET_OFFSET.bottom}px;
  z-index: 1;
`;

const ToggleButton = styled(animated.button)`
  position: relative;
  display: inline-flex;
  min-height: ${({ theme }) => theme.launchButton.minSize};
  min-width: ${({ theme }) => theme.launchButton.minSize};
  backface-visibility: hidden;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: ${({ rectangular }) => rectangular ? "0.25rem 0.75rem" : "0.25rem"};
  color: #fff;
  background-color: ${({ theme }) => theme.primaryColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.xxl};
  font-weight: 500;
  box-shadow: none;
  text-transform: none;
  border: none;
  border-radius: 2rem;
  border-bottom-${({ orientation }) => orientation}-radius: ${
    ({ rounded }) => rounded ? "2rem" : "0"
  };
  box-shadow: ${({ rounded }) => rounded
    ? "1px 2px 0.25rem rgba(0,0,0,0.25)"
    : "0 0 0.25rem rgba(0,0,0,0.3)"
  };
  cursor: pointer;
  ${({ customImage }) => customImage && `
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  `}
  transform-origin: center ${({ rectangular, orientation }) => rectangular
    ? orientation
    : "center"};
`;

const CustomLogo = styled.img`
  max-height: ${({ theme }) => theme.launchButton.customIconSize};
  max-width: ${({ theme }) => theme.launchButton.customIconSize};
  height: ${({ theme }) => theme.launchButton.customIconSize};
`;

const Label = styled.span`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
`;

const Notification = styled.div`
  position: absolute;
  right: -0.7em;
  top: -0.7em;
  min-width: 1.5rem;
  height: ${({ theme }) => theme.xxl};
  border-radius: 1.5rem;
  color: ${({ fontColor}) => fontColor || "#fff"};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.red};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.sm};
  color: white;
`;

function WidgetToggleButton({ isWidgetOpen, toggleWidgetOpen }) {
  const [animateHover, setAnimateHover] = useState(false);

  const platform = useSelector((state) => state?.org?.platform);
  const orgDetails = useSelector((state) => state?.org?.details);
  const parentOrigin = useSelector((state) => state?.org?.parentOrigin);
  const orientation = useSelector((state) => state?.content?.widgetOrientation);
  const customizations = useSelector((state) => state?.org?.customizations);
  const assetUrls = useSelector((state) => state?.org?.assetUrls);
  const chatOn = useSelector((state) => state?.chat?.chatOn);

  const chatStage = useSelector((state) => state?.chat?.chatStage);
  const unreadMessages = useSelector((state) => state?.chat?.unreadMessages);
  const lastActivity = useSelector((state) => state?.chat?.lastActivity);

  const widgetSize = useSelector((state) => state?.content?.widgetSize);

  const webSocketContext = useContext(WebSocketContext);

  const isExpanded = widgetSize === WIDGET_SIZE_EXPANDED;
  const talkingToAgent = chatStage === INTERACTED_WITH_AGENT;

  const dispatch = useDispatch();
  const theme = useTheme();

  const { setFeedback } = useFeedback(WIDGET_SUB_EVENT);
  const { localizeText } = useLocalization();

  const chatActionsFn = getChatActions(dispatch);

  const hoverAnimation = useSpring({
    transform: animateHover
      ? "scale(1.1)"
      : "scale(1.0)"
  });

  const buttonRef = useRef(null);
  const [clickAnimation, api] = useSpring(() => ({}), []);

  const rectangular = !!customizations?.launchButtonConfig?.label && !isWidgetOpen;
  const customImage = !!customizations?.launchButtonConfig?.image && !isWidgetOpen;

  const startChat = () => {
    if (isWidgetOpen && !chatOn && chatStage < TRIED_CREATING_TICKET) {
      dispatch(chatStarted());
    }
  };

  const sendMessage = (shouldOpen) => {
    if (shouldOpen && isExpanded) {
      messageParent(JSON.stringify({
        type: EXPAND_MESSAGE
      }), platform, parentOrigin);
    }
    messageParent(JSON.stringify({
      type: shouldOpen ? OPEN_MESSAGE : CLOSE_MESSAGE
    }), platform, parentOrigin);
  };

  const triggerHoverAnimation = () => {
    setAnimateHover(true);
  };

  const triggerClickAnimation = () => {
    api.start({
      from: {
        scale: 0,
        opacity: 0
      },
      to: {
        scale: 1,
        opacity: 1
      }
    });
  };

  const handleHoverAnimation = () => {
    if (!animateHover) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setAnimateHover(false);
    }, TRANSITION_TIME);

    return () => clearTimeout(timeoutId);
  };

  const trackChatActivity = () => {
    if (platform === STANDALONE_WEB) {
      return;
    }
    if (Number.isNaN(parseInt(lastActivity?.timestamp))) {
      return;
    }

    const activityCallback = () => {
      const duration = Date.now() - lastActivity?.timestamp;

      if (duration >= RESET_TIMEOUT) {
        toggleWidgetOpen(false);
        dispatch(clearChat());
      } else if (duration >= TICKET_TIMEOUT) {
        chatActionsFn.sessionTimeout(webSocketContext?.sendJsonMessage);
      }
    };

    const intervalId = setInterval(activityCallback, 30_000);

    return () => clearInterval(intervalId);
  };

  const persistChat = () => {
    if (!orgDetails?.id) {
      return;
    }

    const persistChatLogs = () => {
      if (document.visibilityState === "hidden") {
        chatActionsFn.persistConversationDetails();
      } else if (document.visibilityState === "visible") {
        localStorage.removeItem(orgDetails?.id);
      }
    };

    document.addEventListener("visibilitychange", persistChatLogs);
    return () => document.removeEventListener("visibilitychange", persistChatLogs);
  };

  const logChatRecordInterval = () => {
    const logChatRecord = () => {
      if (chatStage >= INTERACTED_WITH_AI) {
        chatActionsFn.creatingChatRecord();
      }
    }
    const intervalRef = setInterval(logChatRecord, 5_000);
    return () => clearInterval(intervalRef);
  };

  useEffect(persistChat, [orgDetails?.id]);
  useEffect(trackChatActivity, [lastActivity]);
  useEffect(startChat, [isWidgetOpen, chatOn, chatStage]);
  useEffect(handleHoverAnimation, [animateHover]);
  useEffect(logChatRecordInterval, [chatStage]);

  const renderButton = () => {
    const shortTextIconName = "default::short_text";
    const autoAwesomeIconName = "default::auto_awesome";
    const config = customizations?.launchButtonConfig;
    let image = null;
    let logo = null;

    if (isWidgetOpen && parentOrigin) {
      return <ExpandMoreIcon sx={{ fontSize: theme.launchButton.chevronDownIconSize }} />;
    }

    const updateBtnContainer = () => {
      if (!buttonRef.current || chatOn) {
        return;
      }
      const { width, height } = buttonRef.current?.getBoundingClientRect() || {};
      const sizeMargin = 10; // px
      messageParent(JSON.stringify({
        type: LAUNCH_BTN_SIZE_MESSAGE,
        payload: {
          width: Math.ceil(width) + (8 + sizeMargin),
          height: Math.ceil(height) + (6 + sizeMargin)
        }
      }), platform, parentOrigin);
    };

    if (config?.image) {
      image = (
        <img
          alt='ChatAssist Launch Button'
          src={`${assetUrls?.images}/${localizeText(config.image)}`}
          onLoad={updateBtnContainer} />
      );
    } else if (
      config?.logo === shortTextIconName
      || (!config?.logo && !config?.label)
    ) {
      logo = <ShortTextIcon sx={{ fontSize: theme.launchButton.shortTextIconSize }} />;
    } else if (config?.logo === autoAwesomeIconName) {
      logo = <AutoAwesomeIcon sx={{ fontSize: theme.launchButton.autoAwesomeIconSize }} />;
    } else if (config?.logo) {
      logo = (
        <CustomLogo
          src={`${assetUrls?.images}/${config?.logo}`}
          onLoad={updateBtnContainer} />
      );
    }

    setImmediate(() => updateBtnContainer());

    if (image) {
      return image;
    }
    return (
      <>
        {logo}
        {config?.label && (
          <Label>
            {localizeText(config?.label)}
          </Label>
        )}
      </>
    );
  };

  if (platform !== WEB) {
    return null;
  }

  return (
    <ToggleButtonContainer orientation={orientation}>
      <ToggleButton
        ref={buttonRef}
        rounded={isWidgetOpen}
        rectangular={rectangular}
        customImage={customImage}
        orientation={orientation}
        style={{ ...hoverAnimation, ...clickAnimation }}
        onMouseEnter={triggerHoverAnimation}
        onClick={() => [
          triggerClickAnimation(),
          sendMessage(!isWidgetOpen),
          toggleWidgetOpen(!isWidgetOpen),
          setFeedback(null, null,
            isWidgetOpen ? WIDGET_CLOSE_EVENT : WIDGET_OPEN_EVENT, true, ""),
        ]}
      >
        {renderButton()}
        {(!isWidgetOpen && talkingToAgent && unreadMessages?.length > 0) && (
          <Notification
            backgroundColor={customizations?.unreadMessages?.backgroundColor}
            fontColor={customizations?.unreadMessages?.fontColor}
          >
            {unreadMessages?.length > 9
              ? "9+"
              : unreadMessages?.length}
          </Notification>
        )}
      </ToggleButton>
    </ToggleButtonContainer>
  );
}

export default WidgetToggleButton;
