import { useRef, useEffect, useCallback } from "react";
import useWebSocket from "react-use-websocket";
import { CHAT_ON_AMAZON_CONNECT, CHAT_WITH_AGENT } from "../constants/chat";

function useWebSocketWrapper(
  orgId,
  profile,
  parentOrigin,
  parentPathname,
  conversationId,
  socketUrl,
  chatOn,
  chatWith,
  chatMedium,
  chatActionsFn,
  contentActionsFn
) {
  const pingsWithoutPongs = useRef(0);

  const chattingWithAgent = chatWith === CHAT_WITH_AGENT;
  const shouldOpenConnection = chattingWithAgent
    ? chatOn && (chatMedium !== CHAT_ON_AMAZON_CONNECT)
    : chatOn;
  const origin = typeof(parentOrigin) === "string" && typeof(parentPathname) === "string"
    ? parentOrigin + parentPathname
    : parentOrigin;

  const handleSocketOpen = (event) => {
    console.log("Readyly ChatAssist: connection opened");
    pingsWithoutPongs.current = 0;
    chatActionsFn.handleSocketOpen(event, chatWith, chatMedium, sendMessageWrapper);
  };

  const handleSocketClose = (event) => {
    console.log("Readyly ChatAssist: connection closed");
  };

  const handleReconnectStop = (event) => {
    console.log(
      "Readyly ChatAssist: retries exhausted, failed to open a connection"
    );
    chatActionsFn.handleSocketReconnectStop(event, chatMedium);
  };

  const messageFromAI = (response) => {
    if (Object.keys(response).includes("intent")) {
      chatActionsFn.interact(response, sendMessageWrapper);
    } else if (Object.keys(response).includes("similarity")) {
      contentActionsFn.similarity(response);
    } else {
      chatActionsFn.systemMessage(response, sendMessageWrapper);
    }
  };

  const messageFromAgent = (message) => {
    chatActionsFn.handleMessageFromAgent(message, chatMedium, sendMessageWrapper);
  };

  const handleMessage = (event) => {
    try {
      const response = JSON.parse(event?.data);
      pingsWithoutPongs.current = 0;
      if (response === "pong") {
        return;
      }
      if (typeof(response) !== "object" || response === null) {
        console.warn(`Readyly ChatAssist: received malformed response!`);
        return;
      }
      if (chattingWithAgent) {
        messageFromAgent(response);
      } else {
        messageFromAI(response);
      }
    } catch(error) {
      console.warn(
        `Readyly ChatAssist: failed to process message during live chat: ${error?.message}`
      );
    }
  };

  const { sendJsonMessage } = useWebSocket(
    socketUrl,
    {
      share: true,
      reconnectAttempts: 3,
      reconnectInterval: 5_000,
      onOpen: handleSocketOpen,
      onClose: handleSocketClose,
      shouldReconnect: (closeEvent) => closeEvent.wasClean,
      onReconnectStop: handleReconnectStop,
      onMessage: handleMessage
    },
    shouldOpenConnection
  );

  const setUpHeartbeats = () => {
    if (!shouldOpenConnection || !socketUrl) {
      return;
    }

    const ping = () => {
      if (pingsWithoutPongs.current > 3) {
        pingsWithoutPongs.current = 0;
        chatActionsFn.onHeartbeatFailure();
        return;
      }
      sendJsonMessage({action: "ping"}, false);
      pingsWithoutPongs.current = pingsWithoutPongs.current + 1;
    }

    pingsWithoutPongs.current = 0;
    const intervalRef = setInterval(ping, 10_000);

    return () => clearInterval(intervalRef);
  };

  useEffect(setUpHeartbeats, [shouldOpenConnection, socketUrl, sendJsonMessage]);

  const sendMessageWrapper = useCallback((jsonMessage) => {
    if (!jsonMessage) {
      return;
    }
    sendJsonMessage({
      orgId,
      profile,
      origin,
      conversationId,
      readylyService: "chat_assist",
      ...jsonMessage
    });
  }, [orgId, conversationId, profile, origin, sendJsonMessage]);

  return {sendJsonMessage: sendMessageWrapper};
}

export default useWebSocketWrapper;
